import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import api from '../../api';

export const fetchToken = createAsyncThunk('auth/login', (args) => {
  const { email, password } = args;
  return api.post('/auth/login', { email, password }).then((response) => {
    const { token } = response.data;
    const tokenData = jwt_decode(token);
    return { token, ...tokenData };
  });
});

export const sendResetCode = createAsyncThunk('auth/sendResetCode', (args) => {
  const { email } = args;
  return api.post('/auth/password-reset-code', { email }).then((response) => response.data);
});

export const resetPassword = createAsyncThunk('auth/resetPassword', (args) => {
  const { password, verify_password, code } = args;
  return api.post('/auth/password-reset', { password, verify_password, code }).then((response) => response.data);
});

const initialState = {
  user: null,
  error: null,
  isLoading: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    removeToken: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchToken.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(fetchToken.fulfilled, (state, action) => {
      state.isLoading = false;
      const { token, email, first_name, last_name, organization, exp, role, payment_point_id, organizer_id, sub } =
        action.payload;

      const user = {
        token,
        email,
        first_name,
        last_name,
        organization,
        exp,
        role,
        organizer_id,
        payment_point_id,
        id: sub,
      };

      state.user = user;
    });

    builder.addCase(fetchToken.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(sendResetCode.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(sendResetCode.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(sendResetCode.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(resetPassword.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
    });

    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.isLoading = false;
    });

    builder.addCase(resetPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });
  },
});

export const { removeToken } = authSlice.actions;
export default authSlice.reducer;
